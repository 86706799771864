import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import AgeVerification from './controller/AgeVerification';

function App() {
  const [onLine, setOnLine] = useState(false);
  const [streamUrl, setStreamUrl] = useState('');

  useEffect(() => {
    const fetchStreamUrl = async () => {
      const response = await fetch('https://go.stripchat.com/api/models?modelsList=deserearose&sortOrder=asc&strict=1');
      const data = await response.json();
      if(data.models.length > 0){
        const model = data.models[0];
        setOnLine(true);
        setStreamUrl(model.stream.url);
      } else {
        setOnLine(false);
        setStreamUrl('');
      }
    };

    fetchStreamUrl();
  },[]);

  return (
    <>
        
<AgeVerification />
<header className="hero">
  <div className="hero-content">
    <div>
    <h1>Deserea Rose</h1>
    <p>It’s one thing to squirt…. It’s another thing to make it rain. You’ll need more than an umbrella for this
        storm.</p>
    <a href="https://stripchat.com/DesereaRose" className="cta-button" target='_blank' rel="noreferrer">Follow Now</a>
    </div>
    <div className="social">
    <div>
    <a className="link" href="https://stripchat.com/DesereaRose" rel="noopener noreferrer" target="_blank"><img src="images/stripchat-1-logo-svgrepo-com.svg" alt="stripchat" style={{ height: '20px'}} /></a>
    </div>
    <div>
    <a className="link" href="https://my.club/DesereaRose?utm_source=stripchat.com" rel="noopener noreferrer" target="_blank"><img src="images/myclub-gradient.svg" alt="social icon" /></a>
    </div>
    <div>
    <a className="link" href="https://www.twitter.com/deserearose1" rel="nofollow noreferrer noopener" target="_blank"><img src="images/x.svg" alt="twitter" style={{filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(75deg) brightness(99%) contrast(106%)', height: '20px'}} /></a>
    </div>
    <div>
    <a className="link" href="https://www.instagram.com/deserearosee/" rel="nofollow noreferrer noopener" target="_blank"><img src="images/instagram.svg" alt="instagram" style={{filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(75deg) brightness(99%) contrast(106%)', height: '20px'}} /></a>
    </div>
    <div>
    <a className="link" href="https://www.amazon.com/hz/wishlist/ls/2CKSKTWMPKHGK?ref_=wl_share" rel="nofollow noreferrer noopener" target="_blank"><img src="images/amazon.svg" alt="amazon" style={{filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(75deg) brightness(99%) contrast(106%)', height: '20px'}} /></a>
    </div>
    </div>
  </div>
</header>

<div className="status">
{!onLine && <p style={{color:'white', fontSize: '30px', margin: 0}}>Stripchat - Offline</p>}
{onLine && 
    <ReactPlayer url={streamUrl} controls={true}
    playing={true} // Set to true if you want it to play automatically
    muted={true}
    className="react-player"
    config={{
      file: {
        forceHLS: true, // Force HLS playback
      },
    }}/>
}
</div>

<section className="about">
   <img src="profile.png" alt="Model Profile Picture" className="profile-pic"/>
   <div className="about-content">
      <h2>About Deserea Rose – Your Favorite Stripchat Streamer</h2>
      <p>You want to know about me, love? I'm a screamer... I am a walking paradox, to say the least. Which side
         of me you get depends on what you are looking for. I am a switch, and I live and breathe the BDSM
         lifestyle. I have a safe work (Harley), but I can say I've never used it. My passions are dancing and
         writing. My passions are dancing and writing. I love loud music and back roads, anal, chains, whips, pain,
         etc. My favorite colors are black and red for a reason. I am what they call a riot, on that you'll
         never forget. If you want to find out more, you'll just have to view my stream then, won't you? I mean, if
         you dare.</p>
      <p>Moving from Criminal Law to something more in depth. I chose to add to my career and seek to help individuals who have been wronged in the justice system and remain incarcerated wrongfully. I have dove into forensic pathology including crime scene investigation with my litigation and criminal law background. This will give me the ability to not only be on scene, at the autopsy, conduct autopsies, but also assist in secondary investigations to do with people claiming innocence. There is a lot of corruption within the medical examiners office and it needs to change. They are lying to the public and families about causes and manner to protect the law and prosecute innocent people. So yes, there’s more layers to me than just what I am on screen! Appreciate all the love and support onto my way to being Dr. Des.</p>
   </div>
</section>

<section className="gallery">
   <h2>Gallery</h2>
   <div className="gallery-grid">
   <div>
      <img src="images/profile1.jpeg" alt="Gallery Image 1" className="images"/>
      </div><div>
      <img src="images/profile2.jpeg" alt="Gallery Image 2" className="images"/>
      </div><div>      <img src="images/profile3.jpeg" alt="Gallery Image 3" className="images"/></div>
      <div>
      <img src="images/profile4.jpeg" alt="Gallery Image 4" className="images"/>
      </div>
   </div>
</section>

<section className="schedule">
   <h2>Streaming Schedule</h2>
   <table>
      <tr>
         <th>Day</th>
         <th>Time</th>
      </tr>
      <tr>
         <td>Monday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
      <tr>
         <td>Tuesday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
      <tr>
         <td>Wednesday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
      <tr>
         <td>Thursday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
      <tr>
         <td>Friday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
      <tr>
         <td>Saturday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
      <tr>
         <td>Sunday</td>
         <td>12:00 AM – 2:00 AM<br/>8:30 PM – 12:00 AM</td>
      </tr>
   </table>
</section>

<section className="social-proof">
   <h2>What Fans Say</h2>
   <div className="testimonials">
      <blockquote>"Sooo good drink every last drop"</blockquote>
      <blockquote>"She is amazing and puts on great shows"</blockquote>
   </div>
</section>

<footer className="footer">
  <div>
  <p style={{fontSize: '15px'}}>Deserea Rose © 2024</p></div>
  <div className="social">
  <div>
  <a className="link" href="https://stripchat.com/DesereaRose" rel="noopener noreferrer" target="_blank"><img src="images/stripchat-1-logo-svgrepo-com.svg" alt="stripchat" style={{ height: '20px'}} /></a>
  </div>
  <div>
  <a className="link" href="https://my.club/DesereaRose?utm_source=stripchat.com" rel="noopener noreferrer" target="_blank"><img src="images/myclub-gradient.svg" alt="social icon" /></a>
  </div>
  <div>
  <a className="link" href="https://www.twitter.com/deserearose1" rel="nofollow noreferrer noopener" target="_blank"><img src="images/x.svg" alt="twitter" style={{filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(75deg) brightness(99%) contrast(106%)', height: '20px'}} /></a>
  </div>
  <div>
  <a className="link" href="https://www.instagram.com/deserearosee/" rel="nofollow noreferrer noopener" target="_blank"><img src="images/instagram.svg" alt="instagram" style={{filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(75deg) brightness(99%) contrast(106%)', height: '20px'}} /></a>
  </div>
  <div>
  <a className="link" href="https://www.amazon.com/hz/wishlist/ls/2CKSKTWMPKHGK?ref_=wl_share" rel="nofollow noreferrer noopener" target="_blank"><img src="images/amazon.svg" alt="amazon" style={{filter: 'invert(100%) sepia(0%) saturate(7500%) hue-rotate(75deg) brightness(99%) contrast(106%)', height: '20px'}} /></a>
  </div>
  </div>
</footer></>
  );
}

export default App;

import React, { useState, useEffect } from 'react';

const AgeVerification = () => {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    // Check local storage if the user has already verified their age
    const verified = localStorage.getItem('ageVerified');
    if (verified) {
      setIsVerified(true);
    }
  }, []);

  const handleVerify = () => {
    // Set the verification in local storage
    localStorage.setItem('ageVerified', 'true');
    setIsVerified(true);
  };

  const handleReject = () => {
    // Redirect to a safe page or an external site
    window.location.href = 'https://www.google.com'; // Redirects to Google or a custom page
  };

  if (isVerified) return null;

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-modal">
        <h2>Age Verification</h2>
        <p>You must be 18 years or older to enter this site. Please verify your age.</p>
        <button onClick={handleVerify} className="age-verify-button">I am 18 or older</button>
        <button onClick={handleReject} className="age-reject-button">I am under 18</button>
      </div>
    </div>
  );
};

export default AgeVerification;
